import React, { FC, useEffect, useState } from "react";
import UserSettingsInfo from "./InfoView";
import ChangePasswordView from "./ChangePasswordView";
import ChangePinView from "./ChangePinView";
import TwoFactorSettings from "../Settings/TwoFactorSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUserChangePasswordState,
  clearUserChangePinState,
} from "../../redux/reducers/userReducers";
import { RootState } from "../../redux/store";

export type UserSettingsModes = "view" | "changePassword" | "changePin" | "2fa";

const UserSettings: FC = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState<UserSettingsModes>("view");
  const user = useSelector((state: RootState) => state.auth.user);

  const renderContent = (): JSX.Element => {
    switch (mode) {
      case "view":
        return <UserSettingsInfo setMode={setMode} />;
      case "changePassword":
        return <ChangePasswordView setMode={setMode} />;
      case "changePin":
        return <ChangePinView setMode={setMode} />;
      case "2fa":
        return <TwoFactorSettings user={user} />;
      default:
        return <UserSettingsInfo setMode={setMode} />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserChangePasswordState());
      dispatch(clearUserChangePinState());
    };
  }, [mode, dispatch]);

  return <>{renderContent()}</>;
};

export default UserSettings;

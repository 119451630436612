import React, { FC, useEffect, useState } from "react";
import ChangePasswordView from "../../UserSettings/ChangePasswordView";
import ChangePinView from "../../UserSettings/ChangePinView";
import { useDispatch } from "react-redux";
import {
  clearUserChangePasswordState,
  clearUserChangePinState,
} from "../../../redux/reducers/userReducers";
import AdminSettingsInfo from "./AdminSettingsInfo";

export type UserSettingsModes = "view" | "changePassword" | "changePin" | "2fa";

const AdminSettings: FC = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState<UserSettingsModes>("view");

  const renderer = () => {
    switch (mode) {
      case "view":
        return <AdminSettingsInfo setMode={setMode} />;
      case "changePassword":
        return <ChangePasswordView setMode={setMode} />;
      case "changePin":
        return <ChangePinView setMode={setMode} />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserChangePasswordState());
      dispatch(clearUserChangePinState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return <>{renderer()}</>;
};

export default AdminSettings;

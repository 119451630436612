import * as styles from "../../judgment.module.scss";
import React, { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useIsFirstRender } from "usehooks-ts";
import { useParams } from "@reach/router";
import { IsObjectEmpty } from "../../../../../utils";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import { dispatchNotification } from "../../../../../utils/redux";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import FormDatePicker from "../../../../Forms/Inputs/FormDatePicker";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { MultiInputOptions } from "../../../../Forms/types";
import {
  schemaOccupationalMedicineAnonymous,
  schemaOccupationalMedicinePESEL,
  schemaOccupationalMedicineWithoutPESEL,
} from "../../schema";
import FormInputText from "../../../../Forms/Inputs/FormInputText";
import store from "../../../../../redux/store";
import { commonJudgmentDeafults } from "../../utils";
import { updateJudgment } from "../../../../../redux/actions/documentation/judgmentActions";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";
import { OccupationalMedicineJudgmentData } from "../../types";
import FormInputTextArea from "../../../../Forms/Inputs/FormInputTextArea";
import FormDocumentExaminee from "../../../../Forms/FormDocumentExaminee";
import { isFieldRequired, examineeRequiredFields } from "../../../ExaminationCard/utils";
import FromInputCheckbox from "../../../../Forms/Inputs/FormInputCheckbox";
import { selectSelectedExamineeData } from "../../../../../redux/selectors/examinees/selectedExamineeSelectors";

const OccupationalMedicineJudgment: FC = () => {
  const isFirst = useIsFirstRender();
  const { examinationId, documentId } = useParams();

  const judgment = useSelector(
    selectJudgment
  ) as OccupationalMedicineJudgmentData;
  const examinee = useSelector(selectSelectedExamineeData);

  const [isSuitableJugment, setIsSuitableJugment] = useState<boolean | null>(judgment?.isSuitable);
  const [showAppeal, setShowAppeal] = useState(judgment?.isAppeal || false);

  const setExamineeUnder60 = () => {
    const birthDate = (examinee?.birthDate || "");
    let examineeUnder60yo = false;
    if (birthDate !== "") {
      const currentAgeExaminee = dayjs(judgment?.completionDate || "").diff(dayjs(examinee?.birthDate || ""), "year");
      examineeUnder60yo = currentAgeExaminee < 60;
    }

    return examineeUnder60yo;
  }

  const handleNextExaminationDate = () =>
  (setExamineeUnder60()
    ? dayjs(judgment?.completionDate).add(5, "year").subtract(1, "day").toISOString()
    : dayjs(judgment?.completionDate).add(30, "month").subtract(1, "day").toISOString());

  const getCheckedValue = (checked: boolean) => {
    setShowAppeal(checked);
  }

  const getSchema = (data: OccupationalMedicineJudgmentData) => {
    let schema = null;

    if (data?.pesel) {
      schema = schemaOccupationalMedicinePESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = schemaOccupationalMedicineAnonymous;
    }

    return schema ?? schemaOccupationalMedicineWithoutPESEL;
  }

  const methods = useForm<OccupationalMedicineJudgmentData>({
    reValidateMode: "onChange",
    resolver: yupResolver(getSchema(judgment)),
    defaultValues: {
      ...commonJudgmentDeafults(judgment),
      issueCity: judgment?.issueCity || "",
      isAppeal: judgment?.isAppeal || false,
      isSuitable: judgment?.isSuitable,
      justification: judgment?.justification || "",
      judgmentId: judgment?.judgmentId?.toString() || "",
      judgmentNo: judgment?.judgmentNo?.toString() || "",
      legalBasisInput: judgment?.legalBasisInput || "",
      nextExamination: judgment?.nextExamination || handleNextExaminationDate(),
      suitabilityFor: judgment?.suitabilityFor || "",
      appealDeadline: judgment?.appealDeadline || "",
      appealAuthority: judgment?.appealAuthority || "",
      appealDays: judgment?.appealDays,
    },
  });

  const onSubmit = async (data: OccupationalMedicineJudgmentData) => {
    if (data.isAppeal) {
      data.appealDays = 0;
      data.appealAuthority = "";
    }
    await store
      .dispatch(
        updateJudgment({
          ...data,
          examinationId,
          documentId,
        })
      )
      .then(() => void handleNavigateToPreviousPage());
  };

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(methods.formState.errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
  }, [methods.formState.errors, isFirst, showAppeal]);

  const judgmentOptions: MultiInputOptions[] = [
    {
      label: I18n.t(
        "DocumentationManagement.judgment.occupationalMedicine.true"
      ),
      value: true,
    },
    {
      label: I18n.t(
        "DocumentationManagement.judgment.occupationalMedicine.false"
      ),
      value: false,
    },
  ];

  const isSuitableJugmentValue = () => {
    let result = false;
    if (isSuitableJugment === null || (isSuitableJugment !== null && isSuitableJugment)) {
      result = true;
    }

    return result;
  }

  const getCheckedSuitableValue = (fieldName: string, value: string) => {
    setIsSuitableJugment(value === "true");
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles.fieldsContainer}>
            <FromInputCheckbox
              name="isAppeal"
              control={methods.control}
              label={I18n.t("DocumentationManagement.judgment.isAppealTitle")}
              getCheckedValue={getCheckedValue} />
          </div>
          <div>
            <h2>{I18n.t("DocumentationManagement.judgment.judgmentNumber")}</h2>
            <div className={styles.fieldsContainer}>
              <FormInputText
                control={methods.control}
                name="judgmentNo"
                required={isFieldRequired(getSchema(judgment), "judgmentNo")}
                label={I18n.t("Forms.fields.judgmentNumber")}
                error={!!methods.formState.errors.judgmentNo}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <FormDocumentExaminee requiredFields={examineeRequiredFields(getSchema(judgment))} initialData={judgment} />
          </div>
          <div className={styles.fieldJudgmentContainer}>
            <div className={styles.textareaContainer}>
              <FormInputTextArea
                name="legalBasisInput"
                required={isFieldRequired(getSchema(judgment), "legalBasisInput")}
                control={methods.control}
                error={!!methods.formState.errors.legalBasisInput}
                label={I18n.t("Forms.fields.legalBasis")}
              />
            </div>
            <h2>{I18n.t("DocumentationManagement.judgment.occupationalMedicineStatement")}</h2>
            <FormInputMultiRadio
              name="isSuitable"
              control={methods.control}
              options={judgmentOptions}
              error={!!methods.formState.errors.isSuitable}
              updateDynamicField={getCheckedSuitableValue}
            />
            <div className={styles.textareaContainer}>
              <FormInputTextArea
                control={methods.control}
                name="suitabilityFor"
                error={!!methods.formState.errors.suitabilityFor}
              />
            </div>
          </div>
          <div className={styles.fieldJudgmentContainer}>
            <h2>{I18n.t("DocumentationManagement.judgment.remarks")}</h2>
            <h3>
              {I18n.t(
                "DocumentationManagement.judgment.occupationalMedicine.justification"
              )}*
            </h3>
            <div className={styles.textareaContainer}>
              <FormInputTextArea
                control={methods.control}
                name="justification"
                error={!!methods.formState.errors.justification}
              />
            </div>
          </div>
          <div className={styles.fieldsSectionContainer}>
            {isSuitableJugmentValue() && <FormDatePicker
              control={methods.control}
              name="nextExamination"
              label={I18n.t("Forms.fields.nextExamination")}
              error={!!methods.formState.errors.nextExamination}
            />}
            <div className={styles.fieldsContainer}>
              <FormInputText
                control={methods.control}
                name="issueCity"
                label={I18n.t("Forms.fields.city")}
                error={!!methods.formState.errors.issueCity}
                required={isFieldRequired(getSchema(judgment), "issueCity")}
              />
              <FormDatePicker
                control={methods.control}
                name="issueDate"
                label={I18n.t("Forms.fields.documentDate")}
                error={!!methods.formState.errors.issueDate}
              />
            </div>
          </div>
          {!showAppeal && (
            <>
              <br />
              <div>
                <h2>{I18n.t("DocumentationManagement.judgment.appeal")}</h2>
                <div className={styles.fieldsContainer}>
                  <FormInputText
                    control={methods.control}
                    name="appealAuthority"
                    label={I18n.t("Forms.fields.appealAuthority")}
                    error={!!methods.formState.errors.appealAuthority}
                    required={isFieldRequired(getSchema(judgment), "appealAuthority")}
                  />
                  <h2>{I18n.t("DocumentationManagement.judgment.appealDay")}</h2>
                  <FormInputText
                    control={methods.control}
                    name="appealDays"
                    label={I18n.t("DocumentationManagement.judgment.appealDayTlt")}
                    error={!!methods.formState.errors.appealAuthority}
                    required={isFieldRequired(getSchema(judgment), "appealDays")}
                    minValue={1}
                    maxValue={Number.MAX_VALUE}
                    inputMode="numeric"
                    longInput="short"
                  />
                  <h2>{I18n.t("DocumentationManagement.judgment.appealDay2")}</h2>
                </div>
              </div>
            </>
          )}

          <div className={styles.buttonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default OccupationalMedicineJudgment;

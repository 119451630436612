import * as styles from "../userSettings.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";
import {
  emptyState,
  FormatDate
} from "../../../utils";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { getPsychologicalLabInfo } from "../../../redux/actions/userActions";
import { selectFacilityInfo } from "../../../redux/selectors/userSelectors";
import { dashboardPath, handleNavigateToPage } from "../../../utils/paths";
import { verifyLogin } from "../../../redux/actions/authActions";
import { selectCurrentUser } from "../../../redux/selectors/authSelectors";
import { UserSettingsModes } from "../index";
import { useIsMobile } from "../../../utils/hooks";
import TwoFactorSettings from "../../Settings/TwoFactorSettings";
import { AppDispatch } from "../../../redux/store";

interface Props {
  setMode: (mode: UserSettingsModes) => void;
}

const UserSettingsInfo: FC<Props> = ({ setMode }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useIsMobile();

  const userInfo = useSelector(selectCurrentUser);
  const facilityInfo = useSelector(selectFacilityInfo);

  useEffect(() => {
    const fetchData = async () => {
      console.group('UserSettingsInfo Data Fetch');
      console.log('Starting data fetch');
      await dispatch(getPsychologicalLabInfo()).unwrap();
      const verifyResponse = await dispatch(verifyLogin()).unwrap();
      console.log('verifyLogin response:', verifyResponse);
      console.log('Current userInfo:', userInfo);
      console.groupEnd();
    };

    // Initial fetch
    void fetchData();

    // Set up periodic refresh
    const refreshInterval = setInterval(() => {
      console.log('Running periodic refresh');
      void fetchData();
    }, 2000);

    return () => {
      console.log('Cleaning up refresh interval');
      clearInterval(refreshInterval);
    };
  }, [dispatch]);

  const changePasswordSection = () => {
    return (
      <div className={styles.actionSection}>
        <div>
          <h2 className={styles.header}>
            {I18n.t("UserSettings.passwordChange")}
          </h2>
          <div className={styles.passwordExpiryInfo}>
            {I18n.t("UserSettings.passwordExpiry")}&nbsp;
            <strong>{FormatDate(userInfo?.passwordExpiry)}</strong>
          </div>
          <div>
            <TertiaryButton
              event={() => {
                setMode("changePassword");
              }}
              text={I18n.t("Buttons.change")}
            />
          </div>
        </div>
        <div>
          <h2 className={styles.header}>
            {I18n.t("UserSettings.pinChange")}
          </h2>
          <div>
            <TertiaryButton
              event={() => {
                setMode("changePin");
              }}
              text={I18n.t("Buttons.change")}
            />
          </div>
        </div>
        <div className={styles.infoHidden}>
          <h2 className={styles.header}>
            {I18n.t("Common.twoFactorAuth")}
          </h2>
          <div>
            <TwoFactorSettings user={userInfo} />
          </div>
        </div>
      </div>
    );
  }

  const labUserSection = () => {
    return (
      <div className={styles.infoSection}>
        <div>
          <h2 className={styles.header}>
            {I18n.t("UserSettings.facilityInfo")}
          </h2>
          <div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.labName")}:{" "}
              </span>
              {facilityInfo?.name || emptyState}
            </div>
            <div className={styles.infoRowSubHeaderDiv}>
              {" "}
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.address")}:{" "}
              </span>
              {facilityInfo?.address || emptyState}
            </div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.cin")}:{" "}
              </span>
              {facilityInfo?.cin || emptyState}
            </div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.tin")}:{" "}
              </span>
              {facilityInfo?.tin || emptyState}
            </div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.dateExpire")}:{" "}
              </span>
              {facilityInfo?.dateExpire ? FormatDate(facilityInfo?.dateExpire, I18n.t("Format.ExamineeViewDateFormat")) : emptyState}
            </div>
            <br />
          </div>
        </div>
        <div>
          <h2 className={styles.header}>{I18n.t("UserSettings.userInfo")}</h2>
          <div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.firstName")}:{" "}
              </span>
              {userInfo?.firstName || emptyState}
            </div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.lastName")}:{" "}
              </span>
              {userInfo?.lastName || emptyState}
            </div>
            <div className={styles.infoRowSubHeaderDiv}>
              <span className={styles.infoRowSubHeader}>
                {I18n.t("UserSettings.infoSubHeaders.email")}:{" "}
              </span>
              {userInfo?.email || emptyState}
            </div>
            <br />
          </div>
        </div>
        <div>
          <h2 className={styles.header}>{I18n.t("Helpdesk.contact")}</h2>
          <div>
            <div>
              {I18n.t("UserSettings.helpdeskMsgUser")}
              <div className={styles.contactStyle}><span className={styles.contactStyleHeader}>{I18n.t("Helpdesk.phoneNo")}</span>&nbsp;&nbsp;&nbsp;(+48) 32 249 50 40</div>
              <div className={styles.contactStyle}><span className={styles.contactStyleHeader}>{I18n.t("Helpdesk.mobile")}&nbsp;&nbsp;&nbsp;</span>(+48) 515 131 941</div>
              <div className={styles.contactStyle}><span className={styles.contactStyleHeader}>{I18n.t("Helpdesk.mobile")}&nbsp;&nbsp;&nbsp;</span>(+48) 504 207 947</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const mobileContent = () => {
    return (
      <div className={styles.contentContainer}>
        <span className={styles.header}>
          <NavigationHeaderMobile clickAction={() => handleNavigateToPage(dashboardPath)}>
            {I18n.t("UserSettings.headerMobile")}
          </NavigationHeaderMobile>
        </span>
        <div className={styles.userInfoMobileContainer}>
          {changePasswordSection()}
          {labUserSection()}
          <br />
          <br />
        </div>
      </div>
    );
  }

  const desktopContent = () => {
    return (
      <div className={styles.contentContainer}>
        <span className={styles.header}>
          <h1>{I18n.t("UserSettings.header")}</h1>
        </span>
        <div className={styles.userInfoContainer}>
          {labUserSection()}
          {changePasswordSection()}
        </div>
      </div>
    );
  }


  return isMobile ? mobileContent() : desktopContent();
};

export default UserSettingsInfo;
